// Source.tsx
import React, { useState, useContext, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import DOMPurify from 'dompurify';
import { Source, UUID, Note, Annote, isAnnote } from 'core/types';
import { ConnectDragSource } from 'react-dnd';
import { text, colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';
import { ShownIdsContext } from 'src/context/ShownContext';
import { getDomain } from 'core/utils/urls';
import NumberOfAnnotes from 'src/components/NumberOfAnnotes';
import { Favicon } from 'src/components/Favicon';
import { AnnoteCard } from './Annote';

const FULL_WIDTH = 102.5; // REM
const FULL_HEIGHT = (102.5 * 11) / 8.5; // paper ratio

const Title = styled.span`
  font-family: ${text.family.reading};
  font-weight: ${text.weight.regular};
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  color: ${colors.text.header};
  flex: 1;
  padding: 0;
  margin: 0;

  &:empty::before {
    content: attr(placeholder);
  }
`;

const FullContent = styled.div`
  font-size: 2.5rem; //20px
  line-height: 150%;
  padding: 0 15.5rem 10rem;
  font-family: ${text.family.reading};
  color: ${colors.text.primary};

  picture,
  svg,
  img {
    max-width: 100%; // Adjust this value as needed
    height: auto; // Maintain aspect ratio
  }

  h2 {
    font-size: 24px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 20px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    line-height: 31px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  video {
    max-width: 100%;
    height: auto;
  }

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* For Mozilla, since 1999 */
    white-space: -pre-wrap; /* For Opera 4-6 */
    white-space: -o-pre-wrap; /* For Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    overflow-x: auto; /* Add horizontal scrollbar if needed */
  }

  p {
    margin-bottom: 24px;
  }

  a {
    color: ${colors.text.link};
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.bg.primary};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(15, 15, 15, 0.16);
  border-radius: 2px;
  margin-top: 8px;
  margin-left: 8px;
`;

const Wrapper = styled.div<{ isFull: boolean }>`
  display: block;
  width: ${(props) => (props.isFull ? FULL_WIDTH : 37.5)}rem;
  max-height: ${FULL_HEIGHT}rem;
  position: relative;
  box-shadow: 0px 1px 2px rgba(15, 15, 15, 0.1);
  border-radius: 2px;
  overflow-y: auto;

  contain: paint;

  ${({ isFull }) =>
    isFull &&
    css`
      padding-bottom: 10rem;
    `}

  &:hover ${MenuWrapper} {
    z-index: 2;
    visibility: visible;
  }

  &:hover {
    overflow-y: auto;
  }
`;

const SourceURL = styled.a`
  color: ${colors.text.subtitle};
  font-family: ${text.family.spartan};
  font-optical-sizing: auto;
  font-size: ${text.size.small};
  font-style: normal;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SourceInfoWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${colors.bg.primary}; // Adjust this to match your background color
  display: flex;
  padding: 2.25rem 2rem;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1.25rem;
  flex-shrink: 0;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

const SourceURLWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const AnnotesList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 2.25rem;
`;

const AnnoteListItem = styled.li`
  position: relative;
`;

const AnnotesDivider = styled.div`
  width: 100%;
  opacity: 0.5;
  background: linear-gradient(180deg, #adadad 0%, rgba(217, 217, 217, 0.25) 47.92%, rgba(217, 217, 217, 0) 100%);
  height: 0.75rem;
`;

// Simple div to mark the draggable area
const DraggableArea = styled.div`
  cursor: pointer;
`;

const MenuButton = styled.button<{ isActive: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: ${({ isActive }) => (isActive ? '#E9E9E8' : 'none')};

  &&:hover {
    background: #dbdbda;
  }
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${colors.bg.primary};
  padding: 2.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

// const FullWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   cursor: text;
//   user-select: text;
//   -webkit-user-select: text;
//   -moz-user-select: text;
//   -ms-user-select: text;
// `;

const FullDataLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5.5rem 15rem 4rem;
`;

const FullHeaderSpacer = styled.div`
  height: 10rem;
`;

const FullInfo = styled.div`
  /* position: sticky;
  top: 0;
  z-index: 1; */

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const FullAuthor = styled.span`
  color: ${colors.text.primary};
  text-align: center;
  font-family: ${text.family.spartan};
  font-size: 1.75rem;
  font-style: normal;
  font-weight: ${text.weight.thin};
  line-height: normal;
`;

const FullTitle = styled.span`
  color: ${colors.text.header};
  text-align: center;
  font-family: ${text.family.reading};
  font-size: 4.5rem;
  font-style: italic;
  font-weight: ${text.weight.regular};
  line-height: 120%;
  /* padding: 12.5rem 15rem 0; */
`;

const MenuWrapperSticky = styled(MenuWrapper)`
  position: sticky;
  z-index: 2;
  height: 0;
  /* margin: 8px; */
`;

type SourceDisplayMode = 'MINIMAL' | 'ANNOTES' | 'FULL';

export const SourceCard: React.FC<{
  source: Source;
  dragRef?: ConnectDragSource;
  inPreview?: boolean;
  readOnly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}> = ({ source, dragRef, inPreview = false, readOnly = false }) => {
  const { shownIds, readerURL, showAafterB, hide, surfaceNoteMap, clearReaderUrl } = useContext(ShownIdsContext);
  const [displayMode, setDisplayMode] = useState<SourceDisplayMode>('MINIMAL');
  const [shownAnnotes, setShownAnnotes] = useState<Note[]>([]);

  // TODO - right now we're assuming on all links from a source are annotes
  // this may be an issue eventually

  const cleanURL = getDomain(source.url);

  useEffect(() => {
    const newShownAnnotes = source.links
      .filter((linkId: UUID) => shownIds.includes(linkId))
      .map((linkId: UUID) => surfaceNoteMap[linkId])
      .filter((n) => n?.type === 'annote');

    // React should handle if the array is the same
    setShownAnnotes(newShownAnnotes);
  }, [surfaceNoteMap, shownIds, source.links]);

  // If you start out with an annote, switch to ANNOTES mode
  useEffect(() => {
    if (inPreview) return;
    if (displayMode !== 'MINIMAL') return;

    // If you start out with a reader URL, switch to FULL mode
    if (readerURL === source.url && dragRef) {
      // preview cards don't have a dragref
      setDisplayMode('FULL');
      return;
    }

    const hasShownAnnotes = source.links.some((linkId) => shownIds.includes(linkId));
    if (!hasShownAnnotes) return;
    setDisplayMode('ANNOTES');
  }, [displayMode, setDisplayMode, readerURL, dragRef, inPreview]);

  useEffect(() => {
    if (displayMode === 'FULL') {
      // Signal the extension that there's a new source to highlight
      const event = new CustomEvent('NewSourceToRead', { detail: source.url });
      window.dispatchEvent(event);
    }
  }, [displayMode, source.url]);

  // When display mode changes, we need to show or hide the annotes
  const changeDisplayMode = useCallback(
    (mode: SourceDisplayMode): void => {
      const annoteIds = source.links.filter((linkId) => isAnnote(surfaceNoteMap[linkId]));
      if (mode === 'ANNOTES' || mode === 'FULL') {
        showAafterB(annoteIds, source.id);
      } else {
        // minimal mode
        hide(annoteIds, true);
      }

      // take the url param out of the navigation if no longer in full mode
      if (mode !== 'FULL') clearReaderUrl(source.url);
      setDisplayMode(mode);
    },
    [source.links, source.id, showAafterB, hide, setDisplayMode, surfaceNoteMap, shownIds, clearReaderUrl],
  );

  const sanitizedContent = (): { __html: string } => ({
    __html: DOMPurify.sanitize(source.value),
  });

  const MenuJsx = inPreview ? (
    ''
  ) : (
    <MenuWrapper>
      <MenuButton
        isActive={displayMode === 'MINIMAL'}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          changeDisplayMode('MINIMAL');
        }}
      >
        <Icon type={IconOption.MenuClosed} size="1.25rem" />
      </MenuButton>
      <MenuButton
        isActive={displayMode === 'ANNOTES'}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          changeDisplayMode('ANNOTES');
        }}
      >
        <Icon type={IconOption.MenuOpenA} size="1.25rem" />
      </MenuButton>
      <MenuButton
        isActive={displayMode === 'FULL'}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          changeDisplayMode('FULL');
        }}
      >
        <Icon type={IconOption.MenuOpenB} size="1.25rem" />
      </MenuButton>
    </MenuWrapper>
  );

  const SourceUrlJsx = (
    <SourceURLWrapper>
      <Favicon url={source.url} />
      <SourceURL href={source.url} target="_blank" rel="noopener noreferrer">
        {cleanURL}
      </SourceURL>
    </SourceURLWrapper>
  );

  const NumOfAnnotesJsx = (
    <div
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        changeDisplayMode(displayMode === 'ANNOTES' ? 'MINIMAL' : 'ANNOTES');
      }}
    >
      <NumberOfAnnotes annoteCount={source.links.length} />
    </div>
  );

  return (
    <Wrapper isFull={displayMode === 'FULL'} className="nowheel">
      {displayMode !== 'FULL' && (
        <>
          <DraggableArea ref={dragRef}>
            <SourceInfoWrapper>
              {MenuJsx}

              <Title>{source.title}</Title>
              <Info>
                {SourceUrlJsx}
                {NumOfAnnotesJsx}
              </Info>
            </SourceInfoWrapper>
            {displayMode === 'ANNOTES' && shownAnnotes.length > 0 && (
              <AnnotesList className="nodrag">
                {shownAnnotes.map((annote) => (
                  <AnnoteListItem key={annote.id}>
                    <AnnotesDivider />
                    <AnnoteCard key={annote.id} annote={annote as Annote} />
                  </AnnoteListItem>
                ))}
              </AnnotesList>
            )}
          </DraggableArea>
        </>
      )}
      {displayMode === 'FULL' && (
        <>
          <MenuWrapperSticky>{MenuJsx}</MenuWrapperSticky>
          <FullHeaderSpacer />
          <StickyHeader>
            <FullTitle>{source.title}</FullTitle>
          </StickyHeader>
          <DraggableArea ref={dragRef}>
            <FullInfo>{source.details.author && <FullAuthor>{source.details.author}</FullAuthor>}</FullInfo>
            <FullDataLine>
              {SourceUrlJsx}
              {NumOfAnnotesJsx}
            </FullDataLine>
          </DraggableArea>
          <FullContent data-reader-source-url={source.url} data-reader-source-id={source.id}>
            <div dangerouslySetInnerHTML={sanitizedContent()} />
          </FullContent>
        </>
      )}
    </Wrapper>
  );
};

export default React.memo(SourceCard);
