export enum IconOption {
  Annote = 'annote',
  Check = 'check',
  Close = 'close',
  Comment = 'comment',
  Copy = 'copy',
  Dots = 'dots',
  Down = 'down',
  GreenVerified = 'greenVerified',
  Grid = 'grid',
  Icon = 'icon',
  Left = 'left',
  Like = 'like',
  Liked = 'liked',
  Link = 'link',
  List = 'list',
  Map = 'map',
  Menu = 'menu',
  Minus = 'minus',
  NotVerified = 'notVerified',
  Pen = 'pen',
  Plus = 'plus',
  Reply = 'reply',
  Right = 'right',
  Search = 'search',
  Send = 'send',
  Share = 'share',
  Stack = 'stack',
  Up = 'up',
  Verified = 'verified',
  Pen2 = 'pen2',
  Hashtag = 'hashtag',
  Source = 'source',
  Plus2 = 'plus2',
  Clock = 'clock',
  Trash = 'trash',
  Upload = 'upload',
  Article = 'article',
  MenuOpenA = 'menuOpenA',
  MenuOpenB = 'menuOpenB',
  MenuClosed = 'menuClosed',
  Archive = 'archive',
  ClearBoard = 'clear_board',
  Scissors = 'scissors',
  Settings = 'settings',
}
