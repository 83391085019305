import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';
import { debounceWithFlush } from 'src/helpers/debounce';
import { useProfileContext } from 'src/context/ProfileContext';
import userImgUrl from './user.png';

const DEVELOPER_EMAILS = ['dave@annote.com', 'davefowler@gmail.com', 'silvia.podolakova@gmail.com'];

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
`;

const Button = styled.button`
  background: ${colors.bg.primary};
  border: 1px solid ${colors.lines.link};
  border-radius: 0.75rem;
  padding: 1rem;
  cursor: pointer;
`;

const Overlay = styled.div`
  background: rgba(24, 24, 27, 0.6);
  flex: 1;
`;

const SettingsWrapper = styled.div`
  flex-basis: 48rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 3.125rem;
  background: ${colors.bg.primary};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Header = styled.span`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
`;

const UserDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(200, 202, 203, 1);
  border-radius: 0.75rem;
  padding: 1rem;
  flex: 1;
`;

const EmailGroup = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
`;

const Email = styled.span`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2rem;
`;

const SettingsPhoto = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
`;

const LogOut = styled.div`
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${colors.text.link};
  cursor: pointer;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

const GroupTitle = styled.span`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
`;

const Description = styled.span`
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: ${colors.text.description};
`;

const BlockListTextArea = styled.textarea`
  height: 12rem;
  resize: none;
  border: 1px solid rgba(200, 202, 203, 1);
  border-radius: 0.75rem;

  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.75rem;

  padding: 1.25rem;

  &:active,
  &:focus {
    outline: none;
  }
`;

type SettingsProps = {
  onClose: () => void;
};

const Settings: React.FC<SettingsProps> = ({ onClose }) => {
  const { profile, updateProfile, logout } = useProfileContext();

  const defaultBlockedHosts = (profile?.blockedHosts ?? []).join(', ');

  const [debouncedUpdateProfile, flushDebouncedUpdate] = useMemo(
    () =>
      debounceWithFlush((updatedProfile) => {
        updateProfile(updatedProfile);
      }, 500),
    [updateProfile],
  );

  const handleBlockListChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void | Promise<void> => {
    const newBlockedHosts = e.target.value.split(',').map((host) => host.trim());
    if (!profile || !profile.userId) return;
    debouncedUpdateProfile({ ...profile, blockedHosts: newBlockedHosts });
  };

  const handleClose = (): void => {
    // this ensures the last changes are saved before closing
    flushDebouncedUpdate();
    onClose();
  };

  useEffect(() => {
    return () => {
      // this ensures the last changes are saved before unmounting
      // most of the cases are covered by the onClose function
      // but this is a safety net
      flushDebouncedUpdate();
    };
  }, [flushDebouncedUpdate]);

  const isDeveloper = useMemo(() => {
    return DEVELOPER_EMAILS.includes(profile?.email ?? '') || profile?.email.endsWith('@annote.com');
  }, [profile?.email]);

  return (
    <Wrapper>
      <Overlay onClick={handleClose} />
      <SettingsWrapper>
        <Row>
          <Header>Settings</Header>
          <Icon type={IconOption.Close} onClick={handleClose} size="1.5rem" />
        </Row>
        <Row>
          <UserDetails>
            <EmailGroup>
              <SettingsPhoto src={userImgUrl} />
              <Email>{profile?.email ?? ''}</Email>
            </EmailGroup>
            <LogOut onClick={logout}>Log out</LogOut>
          </UserDetails>
        </Row>
        <Row>
          <Group>
            <GroupTitle>Block list</GroupTitle>
            <Description>List of websites where annote won't be used.</Description>
            <BlockListTextArea defaultValue={defaultBlockedHosts} onChange={handleBlockListChange} />
          </Group>
        </Row>
        {isDeveloper && (
          <Row>
            <Group>
              <GroupTitle>For Developers Only</GroupTitle>
              <Description>Some tools here only developers can see and use</Description>
              <Button
                onClick={() => {
                  throw new Error('This is a test error for Sentry');
                }}
              >
                Send Test Error to Sentry
              </Button>
            </Group>
          </Row>
        )}
      </SettingsWrapper>
    </Wrapper>
  );
};

export default Settings;
