import React from 'react';
import styled from 'styled-components';
import { IconOption } from './IconOption';
import { ReactComponent as AnnoteIcon } from './icons/annote.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as CommentIcon } from './icons/comment.svg';
import { ReactComponent as DotsIcon } from './icons/dots.svg';
import { ReactComponent as DownIcon } from './icons/down.svg';
import { ReactComponent as GridIcon } from './icons/grid.svg';
import { ReactComponent as IconIcon } from './icons/icon.svg';
import { ReactComponent as LeftIcon } from './icons/left.svg';
import { ReactComponent as LikeIcon } from './icons/like.svg';
import { ReactComponent as LikedIcon } from './icons/liked.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as MinusIcon } from './icons/minus.svg';
import { ReactComponent as PenIcon } from './icons/pen.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as ReplyIcon } from './icons/reply.svg';
import { ReactComponent as RightIcon } from './icons/right.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as SendIcon } from './icons/send.svg';
import { ReactComponent as ShareIcon } from './icons/share.svg';
import { ReactComponent as UpIcon } from './icons/up.svg';
import { ReactComponent as Check } from './icons/check.svg';
import { ReactComponent as GreenVerified } from './icons/green_verified.svg';
import { ReactComponent as NotVerified } from './icons/not_verified.svg';
import { ReactComponent as Verified } from './icons/verified.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as Pen2 } from './icons/pen2.svg';
import { ReactComponent as Hashtag } from './icons/hashtag.svg';
import { ReactComponent as Source } from './icons/source.svg';
import { ReactComponent as Plus2 } from './icons/plus2.svg';
import { ReactComponent as Clock } from './icons/clock.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as Trash } from './icons/trash.svg';
import { ReactComponent as Stack } from './icons/stack.svg';
import { ReactComponent as Article } from './icons/article.svg';
import { ReactComponent as MenuClosed } from './icons/menu_closed.svg';
import { ReactComponent as MenuOpenA } from './icons/menu_open_a.svg';
import { ReactComponent as MenuOpenB } from './icons/menu_open_b.svg';
import { ReactComponent as Archive } from './icons/archive.svg';
import { ReactComponent as ClearBoard } from './icons/clear_board.svg';
import { ReactComponent as Scissors } from './icons/scissors.svg';
import { ReactComponent as Settings } from './icons/settings.svg';

const ICONS = {
  annote: AnnoteIcon,
  check: Check,
  close: CloseIcon,
  comment: CommentIcon,
  copy: Copy,
  dots: DotsIcon,
  down: DownIcon,
  greenVerified: GreenVerified,
  grid: GridIcon,
  icon: IconIcon,
  left: LeftIcon,
  like: LikeIcon,
  liked: LikedIcon,
  link: LinkIcon,
  list: ListIcon,
  map: MapIcon,
  menu: MenuIcon,
  minus: MinusIcon,
  notVerified: NotVerified,
  pen: PenIcon,
  plus: PlusIcon,
  reply: ReplyIcon,
  right: RightIcon,
  search: SearchIcon,
  send: SendIcon,
  share: ShareIcon,
  up: UpIcon,
  verified: Verified,
  pen2: Pen2,
  hashtag: Hashtag,
  source: Source,
  plus2: Plus2,
  clock: Clock,
  upload: Upload,
  trash: Trash,
  stack: Stack,
  article: Article,
  menuClosed: MenuClosed,
  menuOpenA: MenuOpenA,
  menuOpenB: MenuOpenB,
  archive: Archive,
  clear_board: ClearBoard,
  scissors: Scissors,
  settings: Settings,
};

type IconProps = {
  type: IconOption;
  size: string;
  color?: string;
  className?: string;
  onClick?: () => void | Promise<void>;
};

const IconWrapper = styled.div<{ color?: string; size: string; isClickable: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  ${(props) => (props.isClickable ? 'cursor: pointer' : '')};

  svg {
    width: 100%;
    height: 100%;
  }
  svg path {
    ${(props) => (props.color ? `fill: ${props.color}` : '')};
  }
`;

const Icon: React.FC<IconProps> = ({ type, color, size, className, onClick }) => {
  const handleIconClick = (): void => {
    if (!onClick) return;
    onClick();
  };

  const IconSvg = ICONS[type];
  return (
    <IconWrapper className={className} color={color} size={size} isClickable={!!onClick} onClick={handleIconClick}>
      <IconSvg />
    </IconWrapper>
  );
};

export default Icon;
