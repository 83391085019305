/* eslint-disable no-param-reassign */
// The Brain keeps track of all the Notes and their state

import { createContext, ReactNode, useContext, useCallback, useMemo, useState } from 'react';
import { Note, TypeOfNote, UUID, NoteMap, SearchResults, Source } from 'core/types';

import { createNote } from 'core/utils/notes';
import { useDataContext } from './DataContext';
// import { MiniSearchManager } from 'core/dbs/search/miniSearchManager';
import { FocusContext } from './FocusContext';

// Define the interface for the context value
interface BrainContextType {
  createTakeaway: (fromNote?: Note) => Note;
  updateNotes: (notes: Note[]) => void;
  linkNotes: (fromNote: Note, toNote: Note) => [Note, Note];
  unlinkNotes: (fromNote: Note, toNote: Note) => void;
  searchNotes: (query: string, types?: TypeOfNote[], limit?: number) => Promise<SearchResults>;
  getNoteMapByIds: (noteIds: UUID[]) => Promise<NoteMap>;
  getSourceByUrl: (url: string) => Promise<Source | null>;
  updatedNotes: Note[];
}

interface BrainProviderProps {
  children: ReactNode;
}

const BrainContext = createContext<BrainContextType | null>(null);

export const BrainProvider: React.FC<BrainProviderProps> = ({ children }) => {
  const { setFocus } = useContext(FocusContext);
  const { annoteDB } = useDataContext();
  const [updatedNotes, setUpdatedNotes] = useState<Note[]>([]);

  if (!annoteDB) throw new Error('annoteDB is not initialized');

  const getNoteMapByIds = useCallback(
    async (noteIds: string[]): Promise<NoteMap> => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      const notes = noteIds.length > 0 ? await annoteDB.getNotes(noteIds) : [];
      return notes.reduce((noteMap, note) => {
        noteMap[note.id] = note;
        return noteMap;
      }, {} as NoteMap);
    },
    [annoteDB],
  );

  const updateNotes = useCallback(
    (notes: Note[]): void => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      annoteDB.updateNotes(notes);
      setUpdatedNotes(notes); // Use this to trigger updates.
    },
    [annoteDB],
  );

  const getSourceByUrl = useCallback(
    async (url: string): Promise<Source | null> => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      const { notes } = await annoteDB.getNotesByUrl(url);
      const source = notes.find((n) => n.type === 'source') as Source | null;
      return source || null;
    },
    [annoteDB],
  );

  const linkNotes = useCallback(
    (fromNote: Note, toNote: Note): [Note, Note] => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      if (!fromNote.links.includes(toNote.id)) {
        const updatedFromNote = {
          ...fromNote,
          links: [toNote.id, ...fromNote.links],
        };
        const updatedToNote = {
          ...toNote,
          backlinks: [fromNote.id, ...toNote.backlinks],
        };
        updateNotes([updatedFromNote, updatedToNote]);
        return [updatedFromNote, updatedToNote];
      }
      return [fromNote, toNote];
    },
    [annoteDB],
  );

  const unlinkNotes = useCallback(
    (fromNote: Note, toNote: Note): void => {
      if (!annoteDB) throw new Error('annoteDB is not initialized');
      const newFromLinks = fromNote.links.filter((id) => id !== toNote.id);
      const newToBacklinks = toNote.backlinks.filter((id) => id !== fromNote.id);
      updateNotes([
        { ...fromNote, links: newFromLinks },
        { ...toNote, backlinks: newToBacklinks },
      ]);
    },
    [annoteDB],
  );

  const createTakeaway = (creatingNote?: Note): Note => {
    if (!annoteDB) throw new Error('annoteDB is not initialized');
    const newNote = createNote('', 'takeaway');
    setTimeout(() => setFocus(newNote.id), 0);
    console.log('creating takeaway from', creatingNote);
    if (!creatingNote) {
      updateNotes([newNote]);
      return newNote;
    }

    // it was created by another note so let's link them
    newNote.createdFromId = creatingNote.id;
    if (creatingNote.type === 'annote') {
      // Takeaways from annotes should start with the same value and links
      newNote.value = creatingNote.value;
      newNote.links = creatingNote.links;
      // add backlinks to this new note
      // TODO - this isn't the most reliable way to enruse these backlinks
      newNote.links.forEach((id) => {
        annoteDB.getNoteById(id).then((n) => {
          if (!n) return;
          const updatedNote = { ...n, backlinks: [newNote.id, ...n.backlinks] };
          updateNotes([updatedNote]);
        });
      });
    }
    console.log('link notes', newNote, creatingNote);
    // link Notes also saves them
    const [newFromNote] = linkNotes(newNote, creatingNote);
    return newFromNote;
  };

  const brain: BrainContextType = useMemo(
    () => ({
      searchNotes: annoteDB.searchNotes,
      getNoteMapByIds,
      updateNotes,
      getSourceByUrl,
      linkNotes,
      unlinkNotes,
      createTakeaway,
      updatedNotes,
    }),
    [annoteDB, getNoteMapByIds, getSourceByUrl, linkNotes, unlinkNotes, createTakeaway, updatedNotes],
  );

  return <BrainContext.Provider value={brain}>{children}</BrainContext.Provider>;
};

export const useBrainContext = (): BrainContextType => {
  const context = useContext(BrainContext);
  if (context === null) {
    throw new Error('useBrainContext must be used within a BrainProvider');
  }
  return context;
};
